import logo from './logo.svg';
import React, { useState, useEffect, useRef } from "react";
import './App.css';
import io from "socket.io-client";

// const socket = io.connect('https://lvh.me:8080/');

const socket = io.connect('/');

function UI(state, clientId, onClick) {
  const inputRef = useRef();
  const onButtonClick = (e) => {
    // @ts-ignore (us this comment if typescript raises an error)

    e.preventDefault();

    onClick(inputRef.current.value);
    inputRef.current.value = "";
    inputRef.current.focus();
  };

  const onChange = () => {
    inputRef.current.value = inputRef.current.value.replace(/\s/g, '');
  };

  return (
    <div className='main'>
      <h1>Story Time!</h1>
      <p>Take turns writing a story a word at a time! Each player gets 15 seconds per turn!</p>
      <p className='content'>{state.content}</p>
      <div className='users'>
        {state.users !== undefined && state.users.map((user) => (
            <div className={`${user === clientId ? 'myself' : ''} ${user === state.activeUser ? 'active' : ''}`} key={user}>{user}{user === clientId && " (You)"}</div>
          ))}
      </div>
      <form onSubmit={onButtonClick}>
        <input type="text" maxLength={20} onChange={onChange} ref={inputRef}/>
        <button type="submit" disabled={state.activeUser != clientId}>Send Word</button>
      </form>
    </div>
  );
}

function App() {
  const [serverState, setServerState] = useState({});
  const [clientId, setClientId] = useState(null);

  useEffect(() => {
    socket.on("update", (data) => {
      console.log(data);
      setServerState(data);
    });
    socket.on('connect', () => {
      console.log('connected');
      console.log(socket);
      setClientId(socket.id);
    });
  }, [socket]);

  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        {UI(serverState, clientId, (word) => {socket.emit('write', word);})}
      </header>
    </div>
  );
}

export default App;
